@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;700&family=Montserrat&display=swap');


* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #dedede;
}

